<template>
  <Item
    :item="item"
    :parent="parent"
    class="py-8 px-16"
    :class="{
      'mt-8': hasBackground && !item.props.expand && parent.items.length === 1,
    }"
  >
    <div class="flex justify-between items-center w-full">
      <ImageItem
        v-if="logo"
        appearance="org"
        :style="{
          width: `${imgDimensions.width}px`,
          height: `${imgDimensions.height}px`,
        }"
        :item="logo"
        class="h-auto"
        :parent="item"
      />

      <Content
        v-else
        :item="item.items[0]"
        :parent="item"
        class="inline-flex flex-col"
      />

      <div class="flex flex-row-reverse lg:flex-row items-center shrink-0">
        <Navigation
          v-if="navigationItem"
          :item="navigationItem"
          :parent="item"
        />

        <SocialLinks
          v-if="socialLinksItem"
          :item="socialLinksItem"
          :parent="item"
          class="ml-auto lg:ml-8"
        />
      </div>
    </div>
  </Item>
</template>

<script>
// import { get } from 'vuex-pathify'
import blockMixin from '@/mixins/block'
import itemMixin from '@/mixins/item'

export default {
  name: 'PageHeader',

  components: {
    ImageItem: () => import('@/components/Items/ImageItem'),
    SocialLinks: () => import('@/components/Items/SocialLinks'),
    Navigation: () => import('@/components/Items/Navigation'),
    Content: () => import('@/components/Items/Content'),
    Item: () => import('@/components/Item'),
  },

  mixins: [blockMixin, itemMixin],

  computed: {
    navigationItem() {
      return this.item.items.find(item => item.name === 'Navigation')
    },

    imgDimensions() {
      if (!this.logo) {
        return false
      }

      const base = 75
      let scaler = 1 * base

      if (this.imgRatio < 1) {
        const ratioRemainder = 1 - this.imgRatio
        scaler = base + ratioRemainder * ((base * 2.45) / this.imgRatio)
      }

      const width = Math.round(this.imgRatio * scaler)
      const height = Math.round(width * this.imgRatio)

      return {
        width,
        height,
      }
    },

    imgRatio() {
      if (!this.logo) {
        return false
      }

      const { width, height } = this.logo.settings
      return height / width
    },

    logo() {
      return this.item.items.find(item => item.name === 'ImageItem')
    },
  },
}
</script>

<style lang="postcss">
[data-name='PageHeader'] {
  & .content > .content__body h1 {
    @apply text-xl;
  }
}
</style>
